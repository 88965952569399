import {gql, useMutation} from '@apollo/client';
import {useOwnerData} from '../../common';

export const useGenerateCourse = () => {
    const [generate, {data, loading, error}] = useMutation(GENERATE_COURSE);
    const getOwnerData =  useOwnerData();

    const generateCourse = async (event, taskId) => {
        const {id, tasks} = event;
        const ownerData = await getOwnerData();
        const owner = ownerData?.ownerId ? ownerData : {ownerId: ownerData?.customerId};
        const result = await generate({
            variables: {
                id,
                taskIds: taskId ? [taskId] : tasks?.map((task) => task.id),
                owner
            }
        });
        return result?.data?.generateCourse;
    };

    return {
        preference: data?.generateCourse,
        loading,
        error,
        generateCourse
    };
};

const GENERATE_COURSE = gql`
    mutation GenerateCourse($id: Int!, $taskIds: [Int], $owner: OpenAiOwnerCourseInput) {
        generateCourse(id: $id, taskIds: $taskIds, owner: $owner) {
            event {
                id
                alias
                title
            }
        }
    }
`;